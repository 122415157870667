<template>
  <div class='box'>
    <van-icon name="chart-trending-o" />
    商户数据统计
  </div>
  <div class="choice"  style="margin-bottom: 50px;">
    <van-divider :style="{ color: '#df2020', borderColor: '#df2020', padding: '0 16px' }">商户统计</van-divider>
    <van-row type="flex" justify="space-around" class="shuju">
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">本月订单数</div>{{merStatis.current_count}}</van-col>
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">商户总订单数</div> {{merStatis.all_count}}</van-col>
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">商户总收益</div>{{merStatis.all_money}}</van-col>
    </van-row>
    <van-row type="flex" justify="space-around" class="shuju" >
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">上月订单数</div>{{merStatis.last_count}}</van-col>
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">商户本月收益</div>{{merStatis.current_money}}</van-col>
      <van-col span="8" style="border: 2px solid #f6f6f6;"><div style="color: #323233;">商户上月收益</div>{{merStatis.last_money}}</van-col>
    </van-row>
    <van-divider :style="{ color: '#df2020', borderColor: '#df2020', padding: '0 16px' }">门店统计</van-divider>
    <van-cell-group v-for="item in storeList" :key="item" :title="item.name">
      <van-cell title="门店总收益"  :value="item.store_statis.all_money" />
      <van-cell title="门店总订单数"  :value="item.store_statis.all_count" />
      <van-cell title="门店本月收益"  :value="item.store_statis.current_money" />
      <van-cell title="门店本月订单数"  :value="item.store_statis.current_count" />
      <van-cell title="门店上月收益"  :value="item.store_statis.last_money" />
      <van-cell title="门店上月订单数"  :value="item.store_statis.last_count" />
    </van-cell-group>
  </div>
  
  
</template>

<script>
import { statis } from '@/api/merchant'

	export default {
		data() {
      
			return {
        merStatis:{},
        storeList:[],
			}
    },
    mounted() {
      
      this.getStatis()
    },
    
		methods: {
      getStatis(){
        statis({page:1,limit:100}).then((res) => {
          console.log(res)
          this.merStatis = res.data.mer_statis
          this.storeList = res.data.store_list.data
          console.log(this.merStatis)
          console.log(this.storeList)
        })
      },
      clickMerchant(){
        console.log(this.merchant.code)
      },
      clickStore(val){
        logins({sid:val.id}).then((res) => {
          this.$store.commit('SET_TOKEN', res.data.token)
          this.$store.commit('SET_IS_LOGIN', true)
          if (this.$route.query.redirect) window.location.href = this.$route.query.redirect
          else window.location.href = '/'
        })
     }
		},
		
	}
</script>

<style lang="less" scoped>
.box{
  line-height: 7rem;
  font-size: 16px;
  padding-left: 1.4rem;
  color: #323233;
  background: #fff;
}
.choice{
  min-height: calc(100vh);
  ::v-deep .van-cell__value{
    color: #df2020;
  }
  ::v-deep .van-cell-group__title {
    color: #df2020;
}
}
.shuju{
  text-align: center;
  background:#fff;
  font-size: 14px;
  height: 6rem;
  line-height: 3rem;
  color: #df2020;
  
}

</style>
